form
  @apply flex flex-col
  .field
    @apply flex flex-col
    @apply mb-4
  label.label-col
    @apply flex flex-col
  .label
    @apply text-sm
    @apply mb-1
    a
      text-decoration: underline
  label + span
    @apply text-xs
    @apply text-primary
    @apply mt-1
    @apply flex
    @apply w-full
    @apply py-2 px-3
    @apply bg-primary bg-opacity-10
    @apply rounded-sm
    &:before
      content: ''
      @apply text-primary
      background: no-repeat center / contain  url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-corner-left-up'%3E%3Cpolyline points='14 9 9 4 4 9'%3E%3C/polyline%3E%3Cpath d='M20 20h-7a4 4 0 0 1-4-4V4'%3E%3C/path%3E%3C/svg%3E")
      @apply w-4 h-4
      @apply mr-2
  select,input
    &[type="text"],
    &[type="password"],
    &[type="email"]
      @apply border
      //@apply border-gray
      @apply rounded-sm
      @apply bg-white
      transition: all .3s ease
      &:focus
        outline: none
        @apply border-primary
        @apply bg-white
        box-shadow: none

    &[type="checkbox"]
      @apply text-primary
      @apply focus:ring-0 focus:outline-none focus:shadow-none focus:ring-offset-0
      @apply rounded-sm
      @apply border
      width: 1.5em
      height: 1.5em
      @apply mt-1
  
  textarea
    //@apply border-gray
    @apply rounded-sm
    transition: all .3s ease
    &:focus
      outline: none
      @apply border-primary
      box-shadow: none

  .field-checkbox
    @apply mb-4
    label
      @apply flex flex-row
      @apply space-x-4

  .field-dropdown
    @apply mb-4
    label
      @apply flex flex-row

.form-link
  @apply text-sm
  @apply mb-4

.form-addon
  @apply text-center
  @apply p-4