footer
  @apply bg-background
  @apply text-white
  @apply absolute left-0 bottom-0 right-0
  @apply transform translate-y-3/4

  .footer-wrapper
    width: 1170px
    @apply max-w-full
    @apply mx-auto
    @apply py-10 px-4

    a
      @apply text-white

    .footer-list

      li
        @apply flex items-center space-x-2
        @apply text-sm
        @apply mb-2

      svg
        @apply text-primary

    .footer-copy
      @apply text-sm