header
  @apply fixed top-0 left-0
  @apply w-full
  box-shadow: 0 0 15px 0 rgba(0,0,0,.05)
  @apply bg-white
  @apply z-20
  padding: 12px 0

  .header-wrapper
    width: 1170px
    @apply max-w-full
    @apply mx-auto

    .header-logo-wrapper
      padding: 0 25px

      .header-logo
        width: 85px
        height: auto