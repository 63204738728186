.error
  @apply border-primary border
  @apply p-3
  @apply my-4
  @apply rounded-sm
  @apply text-sm
  padding-left: 4em
  position: relative
  &:before
    content: ''
    position: absolute
    top: 0
    left: 0
    background: no-repeat center / 1.5em auto url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-alert-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E")
    width: 3em
    height: 100%
    @apply bg-primary