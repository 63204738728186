@font-face 
  font-family: "headline"
  src: url("../public/fonts/font2.woff2") format("woff2")

@font-face 
  font-family: "body"
  src: url("../public/fonts/font1.woff2") format("woff2")

*
  margin: 0
  box-sizing: border-box

html,
body
  padding: 0
  margin: 0
  @apply font-body
  font-weight: 400
  font-size: 15px
  @apply h-full
  @apply bg-white
  @apply text-bodyColor

body
  @apply relative

  @screen md
    font-size: 16px


#root
  @apply min-h-full
  position: relative

.App
  @apply h-full

.container
  @apply w-full max-w-screen-sm
  @apply mx-auto
  @apply mb-5 md:mb-10
  @apply px-4 md:px-8
  @apply py-28

.headline
  @apply text-3xl md:text-5xl
  @apply mb-8
  @apply font-headline font-bold
  @apply uppercase
  &.headline-wide-words
    @apply text-2xl md:text-5xl

.sub-headline
  @apply text-lg md:text-xl
  @apply mb-4 mt-1
  @apply font-headline font-bold

a
  @apply text-link