.btn
  @apply py-3 px-5
  @apply rounded-sm
  @apply m-px
  @apply inline-block
  @apply text-center
  @apply uppercase
  font-weight: 700
  @apply text-sm
  @apply cursor-pointer
  @apply select-none
  @apply text-bodyColor
  box-shadow: 0 0 0 1px rgba(255,255,255,0.0)
  @apply transition-shadow duration-300
  @screen md
    @apply py-2 px-10

  &.btn-sm
    @apply py-2 px-8
    font-size: .8em
    svg
      width: 1.75em
      height: 1.75em
      min-width: 1.75em
      min-height: 1.75em

  &.btn-short
      @apply py-2 px-4

  &.btn-primary
    @apply bg-primary
    @apply border border-primary
    @apply text-white
    &:hover
      box-shadow: 0 0 0 1px theme('colors.primary')

  &.btn-outline
    @apply bg-white
    @apply border border-primary
    &:hover
      box-shadow: 0 0 0 1px theme('colors.primary')

  &:disabled
    cursor: auto
    @apply bg-neutral-400
    @apply border border-neutral-400
    &:hover
      box-shadow: 0 0 0 1px rgba(255,255,255,0)

.btn-group
  @apply grid grid-cols-1 md:grid-cols-2 gap-2

  button
    @apply w-full