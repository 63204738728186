@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'globals';
@import 'forms';
@import 'buttons';
@import 'header';
@import 'loading';
@import 'error';
@import 'error';
@import 'footer';
@import 'privacy';