
@keyframes donut-spin
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)


.loading-container
  @apply fixed
  @apply top-0 left-0 right-0 bottom-0
  @apply w-full h-full
  @apply bg-white bg-opacity-90
  @apply flex items-center justify-center
  @apply z-10

  .loading
    @apply inline-block
    border: 4px solid theme('colors.primary')
    border-left-color: rgba(255,255,255,0.1)
    @apply rounded-full
    width: 30px
    height: 30px
    animation: donut-spin 1.2s linear infinite
